<template>
  <div class="input">
    <h2 class="input__title">Spinner</h2>
    <div class="input__wrap">
      <h3 class="input__subtitle">Spinner Default</h3>
      <Spinner />
    </div>
    <div class="input__wrap">
      <h3 class="input__subtitle">Spinner Small</h3>
      <Spinner :small="true" />
    </div>
  </div>
</template>

<script>
import Spinner from "@/modules/UiKit/components/Spinner/Spinner";
export default {
  name: "SpinnerPage",
  mixins: [],
  props: {},
  components: { Spinner },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";

.input {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__title {
    width: 100%;
    margin: 0 0 24px;
    color: $text-default;

    &_textarea {
      margin-top: 48px;
    }
  }

  &__subtitle {
    margin: 0 0 16px;
    padding: 0 0 16px;
    border-bottom: 1px solid $border-disabled;
    color: $text-default;
  }

  &__content {
    margin: 24px 0 48px;
  }

  &__wrap {
    width: 48%;
    margin: 24px 0;

    &_default {
      width: 100%;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__item {
    margin: 0 0 8px;
    color: $text-default;
    font-size: 14px;
    line-height: 20px;

    span {
      font-weight: bold;
    }
  }
}
</style>
